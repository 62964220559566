








import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'

@Component({
  components: {
    ButtonBase,
    ModalBase,
  },
})
export default class ModalSelectSeminarGoal extends Mixins(ModalBaseMethod, StyleSubjectBackground) {
  private seasonSettingId = 0
  private subjectCode = ''

  get setStyle(): {} {
    return this.getSubjectStyle(this.subjectCode)
  }

  public async showModal(id: number, subjectCode: string) {
    this.seasonSettingId = id
    this.subjectCode = subjectCode
    this.show()
  }

  private async selectMode(mode: number) {
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken
      .post(`/studentSeasonSettings/${this.seasonSettingId}`, {
        mode: mode,
      })
      .finally(() => {
        location.reload()
      })
  }
}
