














































import { Component, Watch, Mixins, Ref, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import FillRound from '@/components/atoms/FillRound.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import UnitInformation from '@/components/organisms/UnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ModalSelectSeminarGoal from '@/components/organisms/ModalSelectSeminarGoal.vue'
import VueRouter from 'vue-router'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import ModalUnitCount from '@/components/organisms/ModalUnitCount.vue'

Component.registerHooks(['beforeRouteUpdate'])

export type Unit = {
  curriculumSUnitId: number
  subjectCode: string
  name: string
  isCompleted: boolean
}

@Component({
  components: {
    SidebarSwitcher,
    LabelBase,
    ColoredBox,
    ButtonBase,
    FillRound,
    SelectBase,
    UnitInformation,
    StudentBottomPanel,
    ModalSelectSeminarGoal,
    ModalUnitCount,
  },
})
export default class CurriculumSUnits extends Mixins(LessonStudent, ClassModeMethods, ClassModeChangeAble) {
  private subjectNames = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    sh: '社会',
    ri: '理科',
  }

  private statusOptionDatas = [
    { text: 'すべて', value: '0' },
    { text: '未学習', value: '1' },
    { text: '学習済み', value: '2' },
  ]

  private statusSelectedData = '0'

  private unitDatas: Unit[] = []

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private pickUpUnitData: any | null = null

  private noticeCurrentPageText = '小項目選択中'

  private noticePageTitle = ''

  private colorType = 'gray'

  @Ref() modalSelectSeminarGoal!: ModalSelectSeminarGoal

  @Ref() modalUnitCount!: ModalUnitCount

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadDatas()

    this.noticeGroup()

    await this.getExistSupporterCallHistory()
    Vue.prototype.$loading.complete()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    console.info('-- CurriculumSUnits beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })
    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private async loadDatas(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get(`/seasonCurriculumsSUnits/${this.subjectCode}`, {
        params: {
          status: this.statusSelectedData,
        },
      })
      .then((res: any) => {
        this.unitDatas = res.data.publisherCurriculumsSUnits
        if (!res.data.meta.modeSelected) {
          this.modalSelectSeminarGoal.showModal(res.data.meta.seasonSettingId, this.subjectCode)
        }
        if (res.data.meta.recommendCurriculumSUnit) {
          // おすすめ項目の設定
          this.pickUpUnitData = res.data.meta.recommendCurriculumSUnit
        } else {
          this.pickUpUnitData = null
        }
      })
      .catch(() => {
        this.$router.push(`/student/subjects/${this.CLASS_MODE.SEMINAR}`)
      })
  }

  @Watch('statusSelectedData')
  private async statusSelectedDataChange(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  @Watch('lessonId')
  onLessonIdChanged() {
    this.getExistSupporterCallHistory()
  }

  /**
   * 学習を終了するリンク
   * 授業中の場合は授業IDを、授業終了している場合は直近に終了した授業終了30分以内の授業IDを返す
   *
   * @return 学習週を終了するリンク
   */
  private get reviewUrl(): string {
    return `/student/review/${this.lessonId || this.justFinishedLessonId}`
  }

  private callSupporterClicked() {
    if (this.colorType === 'gray') {
      this.colorType = 'darkgray'
    }
    this.callSupporter(true)
  }

  private async getExistSupporterCallHistory() {
    if (this.lessonId) {
      try {
        const res = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/supporterCallHistory`)
        this.colorType = res.data.existSupporterCallHistory ? 'darkgray' : 'gray'
      } catch (e) {
        console.error(`サポーターを呼ぶボタンの履歴取得でエラーが発生しました。 ${e}`)
        throw e
      }
    }
  }

  /**
   * 前回の学習記録表示
   */
  public showModalUnitCount(unit: Unit) {
    this.modalUnitCount.showModal(unit.curriculumSUnitId, unit.name)
  }
}
