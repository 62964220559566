














import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class UnitInformation extends Vue {
  @Prop()
  unit!: { [key: string]: any }
  @Prop()
  showUnitCount?: Function

  get nameClassObject(): { [key: string]: boolean } {
    const { subjectCode, isCompleted } = this.unit
    return {
      'unit-information__name__sugaku': subjectCode === 'su' && !isCompleted,
      'unit-information__name__sugaku-completed': subjectCode === 'su' && isCompleted,
      'unit-information__name__kokugo': subjectCode === 'ko' && !isCompleted,
      'unit-information__name__kokugo-completed': subjectCode === 'ko' && isCompleted,
      'unit-information__name__eigo': subjectCode === 'ei' && !isCompleted,
      'unit-information__name__eigo-completed': subjectCode === 'ei' && isCompleted,
      'unit-information__name__rika': subjectCode === 'ri' && !isCompleted,
      'unit-information__name__rika-completed': subjectCode === 'ri' && isCompleted,
      'unit-information__name__syakai': subjectCode === 'sh' && !isCompleted,
      'unit-information__name__syakai-completed': subjectCode === 'sh' && isCompleted,
    }
  }
  get classCategoriesUrl(): string {
    return `/student/curriculum-s-units/${this.unit.curriculumSUnitId}/class-categories`
  }

  private async showLatestCount(unit: any) {
    if (this.showUnitCount) {
      this.showUnitCount(unit)
    }
  }
}
