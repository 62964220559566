

















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

export type UnitCount = {
  understanding?: {
    questionCount: number
    correctCount: number
  }
  practice?: {
    questionCount: number
    correctCount: number
  }
}

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalUnitCount extends Mixins(ModalBaseMethod) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private classMode = Vue.prototype.$cookies.get('dataGdls').classMode

  private title = ''
  private unitCount: UnitCount = {}

  @Prop({ default: true })
  private showUnderstanding?: boolean

  public async showModal(curriculumSUnitId: number, title: string) {
    Vue.prototype.$loading.start()
    this.title = title
    await this.loadData(curriculumSUnitId)
    Vue.prototype.$loading.complete()
    this.show()
  }

  private async loadData(curriculumSUnitId: number) {
    this.unitCount = {}
    await Vue.prototype.$http.httpWithToken
      .get('/history/resultDrills/latestCounts', {
        params: { userId: this.userId, classModeCode: this.classMode, sUnitId: curriculumSUnitId },
      })
      .then((res: any) => {
        this.unitCount = res.data
        Vue.prototype.$logger.log(res.data)
      })
  }
}
