










import { Component, Vue } from 'vue-property-decorator'
import FillRound from '@/components/atoms/FillRound.vue'

@Component({
  components: {
    FillRound,
  },
})
export default class StudentBottomPanel extends Vue {

  private get topUrl() {
    if (Vue.prototype.$gdlsCookiesV3.isV3()) return '/student/v3/dashboard'
    return '/student/dashboard'
  }
}
